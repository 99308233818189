
// **  Initial State
const initialState = {
  userData: null,
  isAuth: localStorage.getItem("AyHc7zSW7n6rI") ? true : false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuth: true
      }
    case 'GET_USER':
      return {
        ...state,
        userData: action.data
      }
    case 'LOGOUT':
      localStorage.removeItem("AyHc7zSW7n6rI")
      return { ...state, userData: null, isAuth: false }
    default:
      return state
  }
}

export default authReducer
